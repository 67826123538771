import React from 'react'

const HearthEmpty = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.01 28.17'>
    <path
      id='Path_16'
      data-name='Path 16'
      d='M-84.38,27.615a4.656,4.656,0,0,1-1.709.368,2.943,2.943,0,0,1-2.425-1.219,4.977,4.977,0,0,1-.942-3.163,6.77,6.77,0,0,1,.416-2.412,4.366,4.366,0,0,1,1.145-1.757,2.3,2.3,0,0,1,1.561-.655,1.408,1.408,0,0,1,.993.361,1.232,1.232,0,0,1,.384.948,2.541,2.541,0,0,1-.271,1.006c-.055.135-.106.281-.155.429a2.8,2.8,0,0,0-.116.764,1.843,1.843,0,0,0,.616,1.341,2.228,2.228,0,0,0,1.574.567,2.426,2.426,0,0,0,1.893-.825,3.03,3.03,0,0,0,.742-2.106,3.566,3.566,0,0,0-.709-2.164,4.613,4.613,0,0,0-2-1.486,7.452,7.452,0,0,0-2.905-.529,9.067,9.067,0,0,0-4.327,1.045A8.134,8.134,0,0,0-93.73,20.97a7.189,7.189,0,0,0-1.145,3.937,6.507,6.507,0,0,0,3.021,5.375c7.045,4.792,18.4-4.2,18.4-11.1a8.456,8.456,0,0,0-8.458-8.458,8.4,8.4,0,0,0-6.5,3.105,8.4,8.4,0,0,0-6.5-3.105,8.456,8.456,0,0,0-8.458,8.458c0,11.1,14.955,18.534,14.955,18.534a53.641,53.641,0,0,0,5.772-4.269'
      transform='translate(103.915 -10.179)'
      fill='none'
      stroke={props.color || '#ff7002'}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='1.1'
    ></path>
  </svg>
)
export default HearthEmpty
