import React, { useState, useRef, useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import * as typeformEmbed from '@typeform/embed'
import {
  mobileVW,
  letterSpacing,
  colors,
  font,
  desktopBreakpoint,
  desktopVW,
  headerHeight,
} from '../styles'

// Components
import PageHeading from '../components/collection/PageHeading'
import ProductThumbnail from '../components/collection/ProductThumbnail'
import EmailCapture from '../components/shared/EmailCapture'
import SEO from '../components/shared/SEO'
import RichText from '../components/shared/RichText'

const StyledCollection = styled.section`
  margin-top: ${headerHeight.mobile}px;

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${headerHeight.desktop}px;
  }
`

const Category = styled.div`
  position: relative;
`

const Header = styled.div`
  margin-bottom: ${mobileVW(35)};
  padding-top: ${mobileVW(36)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 35px;
    padding-top: 60px;
  }
`

const Heading = styled.h1`
  font-family: ${font.ben};
  margin-bottom: ${mobileVW(16)};
  text-transform: lowercase;
  font-size: ${mobileVW(36)};
  line-height: ${mobileVW(39)};
  text-align: center;
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 48px;
    line-height: 52px;
    margin-bottom: 16px;
  }
`

const Subtitle = styled.h3`
  font-family: ${font.larsseit};
  font-size: ${mobileVW(18)};
  line-height: ${mobileVW(28)};
  text-align: center;
  margin: 0 ${mobileVW(32)};
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 20px;
    line-height: 32px;
    margin: 0 auto 24px;
    max-width: 800px;
  }
`

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${mobileVW(35)} ${mobileVW(16)};
  margin: 0 ${mobileVW(16)} ${mobileVW(67)};

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 35px 40px;
    width: 866px;
    margin: 0 auto 75px;
  }
`

const Form = styled.div`
  width: 100%;
  height: ${mobileVW(600)};
  padding: ${mobileVW(20)} 0;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(900)};
    padding-bottom: ${mobileVW(20)};
  }
`

const StickyButton = styled.button`
  display: inline-block;
  border-radius: ${mobileVW(20)};
  font-family: ${font.marby};
  font-size: ${mobileVW(14)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: lowercase;
  border: 1px solid ${props => (props.color ? props.color : colors.orange)};
  padding: ${mobileVW(10)} ${mobileVW(40)} ${mobileVW(14)};
  color: ${props => (props.color ? props.color : '#fff')};
  background: ${props => (props.color ? 'transparent' : colors.orange)};
  position: sticky;
  position: -webkit-sticky;
  bottom: ${mobileVW(14)};
  width: 100%;
  z-index: 1;
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
    border-radius: ${desktopVW(30)};
    font-size: ${desktopVW(20)};
    padding: ${desktopVW(10)} ${desktopVW(50)} ${desktopVW(16)};
  }
`

const CollectionCategories = styled.header`
  background: #eee3da;
  overflow: scroll;
  margin-bottom: ${mobileVW(36)};
  position: sticky;
  top: ${headerHeight.mobile}px;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 60px;
    top: ${headerHeight.desktop}px;
  }
`

const CollectionCategoriesInner = styled.div`
  display: flex;
  width: fit-content;
  margin: 0 auto;
  padding: ${mobileVW(15)} ${mobileVW(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 15px 16px;
  }
`

const CategoryButton = styled.button`
  background: ${({ active }) => (active ? '#DE742C' : 'none')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#65473F')};
  font-family: ${font.larsseit};
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(22)};
  padding: ${mobileVW(12)} ${mobileVW(48)};
  border-radius: 100vw;
  white-space: nowrap;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    padding: 12px 48px;
  }
`

const ProductsGrid = ({ products }) => (
  <ProductWrapper>
    {products.map(
      ({
        title,
        imageThumbnail,
        imageThumbnailOnHover,
        slug,
        productDescriptionShort,
        shopifyProduct,
        contentType,
        id,
        highlightTitle,
        saleLabel,
        saleLabelColor,
        saleLabelTextColor,
        descriptionPreview,
        expert,
        experts,
      }) => (
        <ProductThumbnail
          key={slug}
          image={imageThumbnail}
          hoverImage={imageThumbnailOnHover}
          title={title}
          description={productDescriptionShort}
          shopifyProduct={shopifyProduct}
          slug={slug}
          highlightTitle={highlightTitle}
          previewText={descriptionPreview?.descriptionPreview}
          saleLabel={saleLabel}
          saleLabelColor={saleLabelColor}
          saleLabelTextColor={saleLabelTextColor}
          id={id}
          fixedSize={true}
          isAcademyProduct={contentType[0] === 'academy'}
          experts={experts ? experts : expert ? [expert] : null}
        />
      ),
    )}
  </ProductWrapper>
)

const Collection = ({
  data: {
    contentfulCollectionPage: {
      subtitle,
      categories,
      slug,
      seoTitle,
      title,
      seoDescription,
      allProducts: allProductsHeading,
    },
  },
}) => {
  const typeformRef = useRef(null)
  const collectionCategories = useMemo(() => {
    if (!categories) return
    if (categories.length === 1) return categories

    const categoryTitles = categories.map(category => category.title)

    const products = categories
      .flatMap(({ products }) => products)
      .filter(
        (product, index, all) =>
          all.findIndex(p => p.id === product.id) === index,
      )

    return [
      ...(categoryTitles.includes(allProductsHeading) || !allProductsHeading
        ? []
        : [
            {
              title: allProductsHeading,
              subtitle,
              products,
            },
          ]),
      ...categories,
    ]
  }, [categories])

  const [activeCategory, setActiveCategory] = useState(
    collectionCategories ? collectionCategories[0].title : '',
  )

  const scrollTo = () => {
    if (typeformRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: typeformRef.current.offsetTop,
      })
    }
  }

  useEffect(() => {
    typeformEmbed.makeWidget(
      typeformRef.current,
      'https://form.typeform.com/to/vDBsSRsD',
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
      },
    )
  }, [typeformRef])

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} slug={slug} />
      <StyledCollection>
        {slug !== 'producten' && categories && categories.length > 1 && (
          <CollectionCategories>
            <CollectionCategoriesInner>
              {collectionCategories &&
                collectionCategories.map(category => (
                  <CategoryButton
                    key={category.title}
                    onClick={() => setActiveCategory(category.title)}
                    active={category.title === activeCategory}
                  >
                    {category.title}
                  </CategoryButton>
                ))}
            </CollectionCategoriesInner>
          </CollectionCategories>
        )}

        {slug === 'producten' ? (
          <>
            <Header>
              <Heading>{title}</Heading>
            </Header>
            <ProductsGrid
              products={
                collectionCategories && collectionCategories[0].products
              }
            />
          </>
        ) : (
          collectionCategories &&
          collectionCategories.map(category => {
            if (category.title === activeCategory) {
              return (
                <React.Fragment key={category.title}>
                  <Header>
                    <Heading>{category.title}</Heading>
                    {category.subtitle && (
                      <Subtitle>
                        <RichText json={category.subtitle.json} />
                      </Subtitle>
                    )}
                  </Header>
                  <ProductsGrid products={category.products} />
                </React.Fragment>
              )
            }
          })
        )}

        {slug === 'pleasure' && (
          <StickyButton onClick={() => scrollTo()}>Doe de quiz</StickyButton>
        )}
        <Form ref={typeformRef} visible={slug === 'pleasure'} />
      </StyledCollection>
    </>
  )
}

export const PageQuery = graphql`
  query Collection($id: String!) {
    contentfulCollectionPage(id: { eq: $id }) {
      slug
      seoTitle
      title
      seoDescription
      subtitle {
        json
      }
      categories {
        title
        subtitle {
          json
        }
        products {
          id
          title
          slug
          productDescriptionShort
          highlightTitle
          descriptionPreview {
            descriptionPreview
          }
          saleLabel
          saleLabelColor
          saleLabelTextColor
          contentType
          expert {
            name
            slug
            title
            jobFunction
            image {
              title
              fluid(maxWidth: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          experts {
            name
            slug
            title
            jobFunction
            image {
              title
              fluid(maxWidth: 60) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          imageThumbnail {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          imageThumbnailOnHover {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          shopifyProduct {
            handle
            variants {
              edges {
                node {
                  price
                }
              }
            }
          }
        }
      }
      allProducts
    }
  }
`

export default Collection
