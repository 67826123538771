import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { setDrawerOpen } from '../../actions'
import { mobileVW, desktopBreakpoint, colors, font } from '../../styles'

// Utils
import { getPathname } from '../../utils'

// Hooks
import { useDictionaryCtx, useLayoutCtx } from '../../hooks/context'
import useAuth from '../../hooks/useAuth'
import useIsMobile from '../../hooks/useIsMobile'
import useContentfulId from '../../hooks/useContentfulId'

// Components
import Image from 'gatsby-image'
import { Link } from 'gatsby'
import Price from '../shared/Price'
import AddToCart from '../layout/AddToCart'
import Button from '../shared/uiComponents/button'
import HearthEmpty from '../graphics/HearthEmpty'
import HearthFull from '../graphics/HearthFull'

const Container = styled.article`
  --label-color: black;
  --label-text-color: white;

  display: flex;
  flex-direction: column;
  z-index: 0;
  overflow: hidden;
`

const ImageContainer = styled.div`
  width: ${({ staticSize }) => (staticSize ? '164px' : mobileVW(164))};
  height: ${({ staticSize }) => (staticSize ? '164px' : mobileVW(164))};
  border-radius: ${({ staticSize }) => (staticSize ? '6px' : mobileVW(6))};
  overflow: hidden;
  position: relative;
  margin-bottom: ${({ staticSize }) => (staticSize ? '9px' : mobileVW(9))};

  .thumbnail-hover {
    opacity: 0;
    z-index: 1;
    transform: translateY(-100%);
    transition: opacity 0.3s ease;
  }

  &:hover {
    .thumbnail-hover {
      opacity: 1;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: 262px;
    height: 262px;
    border-radius: 6px;
    margin-bottom: 9px;
  }
`

const StyledImage = styled(Image)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`

const SaleLabel = styled.strong`
  position: absolute;
  top: ${({ staticSize }) => (staticSize ? '16px' : mobileVW(16))};
  left: ${({ staticSize }) => (staticSize ? '14px' : mobileVW(14))};
  font-family: ${font.larsseit};
  font-weight: normal;
  font-size: ${({ staticSize }) => (staticSize ? '13px' : mobileVW(13))};
  line-height: ${({ staticSize }) => (staticSize ? '17px' : mobileVW(17))};
  padding: 0.5em 1.15em 0.6em;
  background: var(--label-color);
  color: var(--label-text-color);
  border-radius: 100vw;
  text-transform: lowercase;
  z-index: 2;
  min-width: 75px;
  text-align: center;

  &::before {
    content: '';
    display: block;
    width: ${({ staticSize }) => (staticSize ? '19px' : mobileVW(19))};
    height: ${({ staticSize }) => (staticSize ? '19px' : mobileVW(19))};
    border-radius: ${({ staticSize }) => (staticSize ? '2px' : mobileVW(2))};
    background: inherit;
    position: absolute;
    bottom: 0;
    left: 42.5%;
    transform-origin: bottom left;
    transform: rotate(45deg) translateY(-60%) skew(10deg, 10deg);
    z-index: -1;

    @media (min-width: ${desktopBreakpoint}) {
      width: 21px;
      height: 21px;
      border-radius: 2px;
      left: 47.5%;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    top: 24px;
    left: 21px;
  }
`

const LikeButton = styled.button`
  width: 25px;
  height: 25px;
  position: absolute;
  top: ${({ staticSize }) => (staticSize ? '14px' : mobileVW(14))};
  right: ${({ staticSize }) => (staticSize ? '16px' : mobileVW(16))};
  z-index: 3;

  @media (min-width: ${desktopBreakpoint}) {
    top: 14px;
    right: 16px;
  }
`

const LikeButtonDesktop = styled(LikeButton)`
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block;
  }
`

const LikeButtonMobile = styled(Link)`
  width: 25px;
  height: 25px;
  position: absolute;
  top: ${mobileVW(14)};
  right: ${mobileVW(16)};
  z-index: 3;

  @media (min-width: ${desktopBreakpoint}) {
    display: none;
  }
`

const ModalLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Title = styled.h2`
  font-family: ${font.ben};
  font-size: ${({ staticSize }) => (staticSize ? '24px' : mobileVW(24))};
  line-height: ${({ staticSize }) => (staticSize ? '27px' : mobileVW(27))};
  color: #000000;
  text-transform: lowercase;
  font-weight: normal;
  margin-bottom: ${({ academyProduct }) =>
    academyProduct ? mobileVW(5) : '0px'};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 28px;
    line-height: 31px;
    margin-bottom: ${({ academyProduct }) => (academyProduct ? '5px' : '0px')};
  }
`

const Description = styled.p`
  font-family: ${font.larsseit};
  font-size: ${({ staticSize }) => (staticSize ? '14px' : mobileVW(14))};
  line-height: ${({ staticSize }) => (staticSize ? '24px' : mobileVW(24))};
  text-transform: uppercase;
  color: ${({ color }) => color};

  p:nth-of-type(2) {
    &::before {
      display: inline-block;
      content: '•';
      padding-right: 2px;
      @media (min-width: ${desktopBreakpoint}) {
        content: '-';
        padding: 0 4px;
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 14px;
    line-height: 24px;

    p {
      display: inline-block;
    }
  }
`

const PriceContainer = styled.p`
  color: #9b9b9b;
  font-size: ${({ staticSize }) => (staticSize ? '16px' : mobileVW(16))};
  margin-bottom: ${({ staticSize }) => (staticSize ? '12px' : mobileVW(12))};

  span + span {
    color: #de742c;
    margin-left: 0.3125em;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    margin-bottom: 12px;
  }
`

const Highlight = styled.div`
  p {
    font-family: ${font.larsseit};
    font-size: ${({ staticSize }) => (staticSize ? '14px' : mobileVW(14))};
    line-height: ${({ staticSize }) => (staticSize ? '22px' : mobileVW(22))};
    color: #18100e;
    margin-bottom: ${({ staticSize }) => (staticSize ? '12px' : mobileVW(12))};

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 12px;
    }
  }
`

const Info = styled(Highlight)``

const Summary = styled.div`
  font-family: ${font.larsseit};
  color: ${colors.brownDarkest};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(18)};
  text-overflow: ellipsis;

  ${({ showAll }) =>
    !showAll
      ? `
        overflow: hidden;
        display: -webkit-box;
        max-height: ${mobileVW(18 * 4)};
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;`
      : ''}

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    line-height: 22px;
    ${({ showAll }) => (!showAll ? 'max-height: 88px' : '')}
  }
`
const AddToCartContainer = styled.div`
  margin-top: auto;

  button {
    border: solid ${({ staticSize }) => (staticSize ? '2px' : mobileVW(2))}
      #f3a679;
    font-family: ${font.ben};
    font-weight: normal;
    font-size: ${({ staticSize }) => (staticSize ? '18px' : mobileVW(18))};
    line-height: ${({ staticSize }) => (staticSize ? '22px' : mobileVW(22))};
    height: ${({ staticSize }) => (staticSize ? '50px' : mobileVW(50))};
    border-radius: 100vw;
    text-transform: lowercase;
    width: 100%;

    &:hover {
      background: #f3a679;
    }

    @media (min-width: ${desktopBreakpoint}) {
      font-size: 18px;
      line-height: 22px;
      height: 50px;
      border: solid 2px #f3a679;
    }
  }
`

const StyledLink = styled(Link)``

const ExpertsContainer = styled.div`
  position: absolute !important;
  left: 10px;
  bottom: 7px;
  display: flex;
  flex-wrap: wrap;
  flex-flow: wrap-reverse;

  a {
    z-index: 2;
  }
  @media (min-width: ${desktopBreakpoint}) {
    left: 14px;
    bottom: 4px;
  }
`
const ExpertPic = styled(Image)`
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-right: 3px;
  margin-bottom: 3px;
  @media (min-width: ${desktopBreakpoint}) {
    width: 59px;
    height: 59px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
`

const ButtonWrapper = styled.div`
  margin: 12px 0 24px;
`

const ProductThumbnail = ({
  image,
  title,
  description,
  shopifyProduct,
  slug,
  handle,
  saleLabel,
  saleLabelColor,
  saleLabelTextColor,
  highlightTitle,
  previewText,
  hoverImage,
  id: productId,
  staticSize = false,
  onRemoveFavourite,
  isAcademyProduct,
  experts,
}) => {
  const { user, ready } = useAuth()
  const { node_locale, authenticationPage } = useLayoutCtx()
  const [savedProduct, setSavedProduct] = useState(false)
  const [showFullDescription, setShowFullDescription] = useState(false)
  const dictionary = useDictionaryCtx()
  const isMobile = useIsMobile()

  const id = useContentfulId('product', productId)

  const dispatch = useDispatch()

  useEffect(() => {
    if (user) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .onSnapshot(doc => {
          if (doc.exists) {
            const { saved } = doc.data()

            setSavedProduct(saved)
          }
        })
    }
  }, [user])

  const saveProduct = () => {
    if (!savedProduct) {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .set(
          {
            description,
            slug,
            title,
            image,
            id,
            saved: true,
          },
          { merge: true },
        )

      setSavedProduct(true)
    } else {
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .collection('products')
        .doc(id)
        .delete()

      if (onRemoveFavourite && typeof onRemoveFavourite === 'function') {
        onRemoveFavourite(id)
      }

      setSavedProduct(false)
    }
  }

  return (
    <Container
      staticSize={staticSize}
      className='thumbnail'
      style={{
        '--label-color': saleLabelColor,
        '--label-text-color': saleLabelTextColor,
      }}
    >
      <ImageContainer
        className='thumbnail__image-container'
        staticSize={staticSize}
      >
        <StyledImage fluid={image.fluid} alt={title} />
        {hoverImage && (
          <StyledImage
            className='thumbnail-hover'
            fluid={hoverImage.fluid}
            alt={title}
          />
        )}

        {saleLabel && <SaleLabel staticSize>{saleLabel}</SaleLabel>}

        {ready && user ? (
          <LikeButton onClick={() => saveProduct()} staticSize={staticSize}>
            {savedProduct ? (
              <HearthFull color='#CD8F6E' />
            ) : (
              <HearthEmpty color={isAcademyProduct ? 'white' : '#010101'} />
            )}
          </LikeButton>
        ) : (
          <>
            <LikeButtonDesktop onClick={() => dispatch(setDrawerOpen(true))}>
              <HearthEmpty color={isAcademyProduct ? 'white' : '#010101'} />
            </LikeButtonDesktop>

            <LikeButtonMobile
              to={getPathname(node_locale, authenticationPage.slug)}
            >
              <HearthEmpty color={isAcademyProduct ? 'white' : '#010101'} />
            </LikeButtonMobile>
          </>
        )}

        <ModalLink to={getPathname(node_locale, slug)} />
        {experts ? (
          <ExpertsContainer>
            {experts.map(expert => (
              <StyledLink
                to={getPathname(node_locale, expert.slug)}
                key={expert.slug}
              >
                <ExpertPic
                  fluid={expert.image.fluid}
                  alt={expert.image.title}
                />
              </StyledLink>
            ))}
          </ExpertsContainer>
        ) : null}
      </ImageContainer>

      <Title
        className='thumbnail__title'
        staticSize={staticSize}
        academyProduct={isAcademyProduct}
      >
        {title}
      </Title>

      <Description
        className='thumbnail__description'
        staticSize={staticSize}
        color={isAcademyProduct ? colors.orange : colors.brownDarkest}
      >
        {description}
      </Description>

      {isAcademyProduct && experts
        ? experts.map(expert => (
            <Description
              key={expert.title}
              className='thumbnail__description'
              staticSize={staticSize}
              color={colors.brownDarkest}
            >
              <span>{expert.title}</span> - <span>{expert.jobFunction}</span>
            </Description>
          ))
        : null}

      {shopifyProduct && (
        <PriceContainer className='thumbnail__price' staticSize={staticSize}>
          <Price shopifyProduct={shopifyProduct} />
        </PriceContainer>
      )}

      {highlightTitle && !isAcademyProduct && (
        <Highlight className='thumbnail__highlight' staticSize={staticSize}>
          <p>{highlightTitle}</p>
        </Highlight>
      )}

      {previewText && isAcademyProduct && (
        <Info className='thumbnail__highlight' staticSize={staticSize}>
          <Summary showAll={showFullDescription}>{previewText}</Summary>
          {isAcademyProduct ? (
            <ButtonWrapper>
              {!isMobile && previewText.length > 150 ? (
                <Button
                  ctaType='link'
                  cta={() => setShowFullDescription(!showFullDescription)}
                  text={showFullDescription ? 'READ LESS' : 'READ MORE'}
                />
              ) : isMobile && previewText.length > 100 ? (
                <Button
                  ctaType='link'
                  cta={() => setShowFullDescription(!showFullDescription)}
                  text={showFullDescription ? 'READ LESS' : 'READ MORE'}
                />
              ) : null}
            </ButtonWrapper>
          ) : null}
        </Info>
      )}

      {shopifyProduct && (
        <AddToCartContainer staticSize={staticSize}>
          {isAcademyProduct ? (
            <StyledLink to={getPathname(node_locale, slug)}>
              <button>{dictionary.academyButtonLabel}</button>
            </StyledLink>
          ) : (
            <AddToCart handle={shopifyProduct.handle} upsell={handle} />
          )}
        </AddToCartContainer>
      )}
    </Container>
  )
}

export default ProductThumbnail
