import React from 'react'

const HearthFull = props => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 31.01 28.17'>
    <path
      id='Path_16'
      data-name='Path 16'
      d='M-82.638,33.452c5.411-5.414,9.183-7.371,9.183-14.265a8.456,8.456,0,0,0-8.458-8.458,8.4,8.4,0,0,0-6.5,3.105,8.4,8.4,0,0,0-6.5-3.105,8.456,8.456,0,0,0-8.458,8.458c0,11.1,14.955,18.534,14.955,18.534a53.641,53.641,0,0,0,5.772-4.269'
      transform='translate(103.915 -10.179)'
      fill={props.color || '#ff7002'}
      stroke={props.color || '#ff7002'}
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='1.1'
    ></path>
  </svg>
)
export default HearthFull
