import React from 'react'
import styled from 'styled-components'
import RichText from '../shared/RichText'
import {
  mobileVW,
  letterSpacing,
  font,
  desktopBreakpoint,
  desktopVW,
} from '../../styles'

const StyledHeading = styled.div`
  font-size: ${mobileVW(25)};
  line-height: ${mobileVW(31)};
  letter-spacing: ${letterSpacing(6)};
  color: ${props => props.color};
  font-family: ${font.ben};
  margin: ${mobileVW(80)} 0 ${mobileVW(60)};
  font-weight: normal;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(38)};
    line-height: ${desktopVW(48)};
    margin: ${desktopVW(140)} 0 ${desktopVW(80)};
  }

  b {
    font-family: ${font.marby};
    font-weight: normal;
  }
`

const PageHeading = ({ heading, color }) => (
  <StyledHeading color={color}>
    <RichText json={heading} />
  </StyledHeading>
)

export default PageHeading
